

















import { Component, Vue } from 'vue-property-decorator';
import AccountLogin from './account-login.vue';

@Component({
  name: 'Login',
  components: {
    AccountLogin,
  },
})
export default class Login extends Vue {

}
