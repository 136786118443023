













































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Input, Button } from '@h3/antd-vue';
import { namespace } from 'vuex-class';
import { RsaEncrypt } from '@WorkPlatform/common/rsg';
import { LoginService, ModifyPasswordService, EnterpriseService } from '@WorkPlatform/services';
import ChangePassword from './change-password-dialog.vue';

const Global = namespace('Global');

interface LoginData {
  username: string;
  password: string;
  ruleToken?: string;
  passRule?: string;
  baseUserId?: string;
}

let submitTimeoutId: any = null;

@Component({
  name: 'AccountLogin',
  components: {
    AInput: Input,
    AInputPassword: Input.Password,
    AButton: Button,
    ChangePassword,
  },
})
export default class AccountLogin extends Vue {
  @Global.Action('getAllPerms') getAllPerms!: any;

  loginData: LoginData = {
    username: '',
    password: '',
  };

  loginError: string = '';

  isSubmitAble: boolean = true;

  submitTime: number = 0;

  submitting: boolean = false;

  isTriggerShake: boolean = false;

  isShowModifyPassword: boolean = false;

  // 判断是否需要重定向
  get isRedirect () {
    return window.location.href.includes('?redirect_url=');
  }

  @Watch('loginData', { deep: true })
  onDataChange (loginData: LoginData) {
    loginData.username = loginData.username.replace(/(^\s*)|(\s*$)/g, '');
    this.isSubmitAble = !loginData.username || !loginData.password;
  }

  validate () {
    const { username, password }: LoginData = this.loginData;
    if (!username || !password) {
      return false;
    }

    return true;
  }

  created () {
    if (process.env.VUE_APP_LOCAL) {
      const name = process.env.VUE_APP_ACCOUNT_NAME || 'admin';
      const password = process.env.VUE_APP_ACCOUNT_PASSWORD || '123456';
      this.loginData.username = name;
      this.loginData.password = password;
    }
  }

  async submit () {
    this.submitting = true;
    const { username, password }: LoginData = this.loginData;

    try {
      const resKey: any = await LoginService.getKey();

      if (!resKey.success) return;

      const dataKey: any = resKey.data;

      const { index, key } = dataKey;

      const rsaPassword: any = RsaEncrypt(password, key);

      // rsa加密结束
      const params: any = {
        username: username,
        password: rsaPassword,
        client_id: 'api',
        index,
      };

      const resLogin: any = await LoginService.login(params);
      if (!resLogin.success || !resLogin.data) return;

      if (resLogin.data.specification) {
        this.submitting = false;
        this.loginData.ruleToken = (resLogin.data as any).access_token;
        const resRule = await ModifyPasswordService.getPasswordRuleInfo(this.loginData.ruleToken);

        if (resRule.success) {
          this.isShowModifyPassword = true;
          this.loginData.passRule = resRule.data;
          this.loginData.baseUserId = resLogin.data?.userinfo?.id;
          return;
        }
      }

      this.setUserInfoToLocal(resLogin.data, index);

      await this.getLoginInfo();
      await this.setRefreshTokenSwitch();
      await this.getAllPerms();

      this.goPage();
    } catch (e) {
      const getErrorStatus = (error: any) =>
        error.httpStatus || (error.response && error.response.status);
      const status: any = getErrorStatus(e);

      const wait: number = 1000;

      if (status === 403) {
        this.loginError = '账号或密码有误';

        this.submitTime = this.submitTime + 1;

        if (this.submitTime > 2) {
          this.isTriggerShake = true;
        }

        clearTimeout(submitTimeoutId);

        submitTimeoutId = setTimeout(() => {
          this.isTriggerShake = false;
          clearTimeout(submitTimeoutId);
        }, wait);
      }
    } finally {
      this.submitting = false;
    }
  }

  setInfoToLocal (data: any) {
    enum UnitType {
      Dept = 1,
      Role,
      User,
    }

    const user: any = {
      userInfo: { id: data.id, name: data.name, username: data.username, type: UnitType.User },
      departmentInfo: { id: data?.mainDepartmentId, name: data?.mainDepartmentName, type: UnitType.Dept },
    };
    localStorage.setItem('user', JSON.stringify(user));
  };

  async getLoginInfo () {
    LoginService.getLoginInfo().then((res: any) => {
      if (res.success && res.data) {
        this.setInfoToLocal(res.data);
      }
    });
  }

  setUserInfoToLocal (data: any, index: string) {
    const userInfo: any = {
      name: data.userinfo ? data.userinfo.name : '-',
      username: data.userinfo ? data.userinfo.username : '-',
      mobile: data.userinfo.mobile || '-',
      userId: data.user_id,
      imgUrl: data.userinfo.imgUrl,
    };
    const expireInfo = {
      expired: data.expiration,
      refresh_token: data.refresh_token,
    };
    const token = (data as any).access_token;

    const assetSSO = {
      username: userInfo.username || '-',
      name: userInfo.name || '-',
      mobile: userInfo.mobile || '-',
      client_id: 'api',
      index,
    };

    localStorage.setItem('assetSSO', JSON.stringify(assetSSO));
    localStorage.setItem('expireInfo', JSON.stringify(expireInfo));
    localStorage.setItem('token', token);
    // 登录后，设置cookie
    const expires = new Date(data.expiration).toUTCString();
    document.cookie = `access_token=${token}; expires=${expires};path=/;`;
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  /**
   * 设置刷新token开关
   */
  async setRefreshTokenSwitch () {
    const { data } = await LoginService.getRefreshTokenSwitch();
    const key = 'refreshTokenSwitch';
    if (data) {
      localStorage.setItem(key, String(true));
    } else {
      localStorage.removeItem(key);
    }
  }

  hasPermission (code: string) {
    const perm: any = JSON.parse(localStorage.getItem('perm') || '{}');
    return perm.permList.some((item: any) => {
      return item.code.includes(code);
    });
  }

  async goPage () {
    const isInitEnterprise: boolean = await this.checkInitEnterprise();

    if (!isInitEnterprise) {
      return;
    }

    if (this.isRedirect) {
      this.goRedirectPage();
    } else {
      this.goPlatformHome();
    }
  }

  goRedirectPage () {
    const redirectUrl: string = this.$route.query.redirect_url as string;

    if (
      (redirectUrl.includes('/app_studio') && !this.hasPermission('development')) ||
      (redirectUrl.includes('/management') && !this.hasPermission('management'))
    ) {
      this.goPlatformHome();
      return;
    }

    window.location.href = redirectUrl.replace('hashsymbol', '#');
  }

  async checkInitEnterprise () {
    const { success, data } = await EnterpriseService.checkEnterpriseExist();
    if (!success) return false;
    if (!data || !data?.id) {
      this.goSetEnterprise();
      return false;
    } else {
      return true;
    }
  }

  /**
   * 跳到工作台
   */
  goPlatformHome () {
    this.$router.push('/work-platform-home').catch(() => { });
  }

  goSetEnterprise () {
    this.$router.push('/set-enterprise');
  }

  closeIt () {
    this.isShowModifyPassword = false;
  }
}
